<template>
  <div>
    <section class="gallery-section">
      <div class="container">
        <div class="gallery">
          <!-- <div class="gallery-slider" ref="clipper">
          <div class="slides" ref="slides">
            <div class="slide">
              <div class="slide-content">
                <div class="slide-image">
                  <img src="@/assets/images/gallery1.png" alt="" />
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="slide-content">
                <div class="slide-image">
                  <img src="@/assets/images/gallery2.png" alt="" />
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="slide-content">
                <div class="slide-image">
                  <img src="@/assets/images/gallery3.png" alt="" />
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="slide-content">
                <div class="slide-image">
                  <img src="@/assets/images/gallery4.png" alt="" />
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="slide-content">
                <div class="slide-image">
                  <img src="@/assets/images/gallery5.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="gallery-nav">
            <div class="btn-group">
              <button
                :class="activeSlide == 1 ? 'btn disabled' : 'btn'"
                id="prevBtn"
                data-cursor-target
                data-cursor-size="lg"
                v-on:click="goToPrev"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#2c3844"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
              </button>
              <button
                :class="activeSlide == 5 ? 'btn disabled' : 'btn'"
                id="nextBtn"
                data-cursor-target
                data-cursor-size="lg"
                v-on:click="goToNext"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#2c3844"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div> -->
          <div class="gallery-grid">
            <div
              class="image"
              data-cursor-target
              data-cursor-size="lg"
              @click="showModal('gallery' + n)"
              v-for="n in 8"
              :key="n"
            >
              <img :src="getImageUrl('gallery' + n)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            type="button"
            class="close"
            @click="hideModal"
            aria-label="Close Modal"
          >
            <img src="@/assets/images/icon-cancel.svg" alt="Close" />
          </button>
          <div class="image">
            <img :src="currentImage" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      isOpen: false,
      currentImage: "",
    };
  },
  methods: {
    getImageUrl(name) {
      var img = require.context("@/assets/images/", false, /\.jpg$/);
      return img("./" + name + ".jpg");
    },
    showModal(image) {
      this.currentImage = this.getImageUrl(image);
      this.gsap.to(".modal", { css: { display: "block" } });
      this.gsap.to(".modal", {
        duration: 0.5,
        opacity: 1,
        ease: "power3.inOut",
      });
      this.gsap.fromTo(
        ".modal-content .image",
        {
          scale: 0,
          y: "30%",
        },
        {
          duration: 0.5,
          scale: 1,
          y: 0,
          ease: "power3.inOut",
        }
      );
      this.gsap.fromTo(
        ".close",
        {
          y: "-100%",
        },
        {
          duration: 0.5,
          y: 0,
          ease: "power3.inOut",
        }
      );
    },
    hideModal() {
      this.gsap.fromTo(
        ".modal-content .image",
        {
          scale: 1,
        },
        {
          duration: 0.5,
          scale: 0,
          ease: "power3.inOut",
        }
      );
      this.gsap.fromTo(
        ".close",
        {
          y: 0,
        },
        {
          duration: 0.5,
          y: "-100%",
          ease: "power3.inOut",
        }
      );
      this.gsap.to(".modal", {
        duration: 0.5,
        opacity: 0,
        ease: "power3.inOut",
      });
      this.gsap.to(".modal", { css: { display: "none" } });
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-section {
  .gallery {
    width: 100%;
    padding: 4rem 0;

    .gallery-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;

      .image {
        cursor: pointer;
        transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &:hover {
          border-radius: 30px;
          filter: grayscale(1);
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: fade-out($black, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  opacity: 0;
  padding: 0 1rem;

  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    max-width: 1000px;
    width: 100%;
    margin: 1.75rem auto;

    .modal-content {
      width: 100%;
      height: 100%;
      position: relative;

      .close {
        position: absolute;
        top: -45px;
        right: -5px;
        background: none;
        border: none;
        appearance: none;
        width: 40px;
        height: 40px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0 30px 40px rgb(0 0 0 / 25%);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .gallery-section {
    .gallery {
      .gallery-grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery-section {
    .gallery {
      .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .gallery-section {
    .gallery {
      .gallery-grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
